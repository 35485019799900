'use strict';

// *****************
//  ************  Ensure that on form submission that hamburger is reset
// ******************

export const westHill = function () {
  //// const btnNavEl = document.querySelector('.wh-btn-mobile-nav');
  const btnHamEl = document.querySelector('.hamburger');
  const headerEl = document.querySelector('.wh_header');

  // enable menu to scroll and close mobile menu if link clicked
  const unlockMenu = document.querySelectorAll('.unlock');

  // stop scroll on modal open
  const docbody = document.getElementsByTagName('BODY')[0];

  //// btnNavEl.addEventListener('click', function () {
  ////   headerEl.classList.toggle('wh_nav-open');
  ////   docbody.classList.toggle('body-no-scroll');
  //// });

  btnHamEl.addEventListener('click', function () {
    btnHamEl.classList.toggle('wh_nav-open');
    headerEl.classList.toggle('wh_nav-open');
    docbody.classList.toggle('body-no-scroll');
  });

  unlockMenu.forEach(item => {
    item.addEventListener('click', function () {
      btnHamEl.classList.remove('wh_nav-open');
      headerEl.classList.remove('wh_nav-open');
      docbody.classList.remove('body-no-scroll');
    });
  });

  document.addEventListener('keydown', e => keyPressHandler(e));

  const keyPressHandler = e => {
    if (e.key === 'Escape' && !headerEl.classList.contains('wh-hidden')) {
      btnHamEl.classList.remove('wh_nav-open');
      headerEl.classList.remove('wh_nav-open');
      docbody.classList.remove('body-no-scroll');
    }
  };
};

// ///////////////

export class WestHillModal {
  constructor() {
    this.modal = document.querySelector('.wh-modal');
    this.overlay = document.querySelector('.wh-overlay');
    this.btnCloseModal = document.querySelector('.wh-btn--close-modal');
    this.btnsOpenModal = document.querySelectorAll('.wh-btn--show-modal');

    this.btnHamEl = document.querySelector('.hamburger');

    // nav menu
    this.headerEl = document.querySelector('.wh_header');

    // stop page scrolling with open modal
    this.docbody = document.getElementsByTagName('BODY')[0];
    this.events();
  }
  events() {
    this.btnsOpenModal.forEach(btn =>
      btn.addEventListener('click', e => this.openModal(e))
    );
    this.btnCloseModal.addEventListener('click', () => this.closeModal());
    this.overlay.addEventListener('click', () => this.closeModal());
    // document.addEventListener('keydown', function (e) {
    //   if (e.key === 'Escape' && !this.modal.classList.contains('hidden')) {
    //     this.closeModal();
    //   }
    // });
    //
    document.addEventListener('keydown', e => this.keyPressHandler(e));
  }

  keyPressHandler(e) {
    if (e.key === 'Escape' && !this.modal.classList.contains('wh-hidden')) {
      this.closeModal();

      // make sure the hamburger on mobile menu is reset
      this.btnHamEl.classList.remove('wh_nav-open');
    }
  }

  openModal(e) {
    e.preventDefault();
    // remove mobile menu
    this.headerEl.classList.remove('wh_nav-open');

    this.modal.classList.remove('wh-hidden');
    this.overlay.classList.remove('wh-hidden');
    this.docbody.classList.add('body-no-scroll');
  }
  closeModal() {
    // make sure the hamburger on mobile menu is reset
    this.btnHamEl.classList.remove('wh_nav-open');

    this.modal.classList.add('wh-hidden');
    this.overlay.classList.add('wh-hidden');
    this.docbody.classList.remove('body-no-scroll');
  }
}
