import '../styles/styles.css';

///////////////////////////////////////////////////
///// turn on the following for visible
// import 'lazysizes';
// import MobileMenu from './modules/MobileMenu';
// import RevealOnScroll from './modules/RevealOnScroll';
// import StickyHeader from './modules/StickyHeader';

// import Modal from './modules/Modal';
// import ModalContents from './modules/ModalsContent';

// // //////////////////////////////////////////////
// import ClientArea from './modules/ClientArea';

//////////////////////////////////////////////////
///////// turn on the following for GOB
// import GobMobileMenu from './modules/GobMobileMenu';
// import ImageGallery from './modules/ImageGallery';
// new ImageGallery();

// // enable for template as well
// import HeroSlider from './modules/HeroSlider';
// const heroSlider = new HeroSlider();

// import Carousel from './modules/Carousel';
// const carousel = new Carousel();

// new GobMobileMenu();

///////////////////////////////////////////////////
///////// turn on the following for Omnifood
// import OmniMobile from './modules/Omnifood';
// new OmniMobile();

///////////////////////////////////////////////////
///////// turn on the following for West Hill
import { westHill, WestHillModal } from './modules/Westhill';
westHill();
new WestHillModal();
///////////////////////////////////////////////////
///////// turn on the following for Bankist
// import {
//   BankistModal,
//   bankistScrolling,
//   bankistNav,
//   bankistTabs,
//   bankistAnim,
//   bankistSticky,
//   bankistReveal,
//   bankistlazyLoad,
//   bankistSlider,
// } from './modules/Bankist';
// new BankistModal();
// bankistScrolling();
// bankistNav();
// bankistTabs();
// bankistAnim();
// bankistSticky();
// bankistReveal();
// bankistlazyLoad();
// bankistSlider();

///////////////////////////////////////////////////
///////// JS Testing
// import { testCode } from './modules/TestCode';
// testCode();

// ////////////////////////////////////////////////
// BusyBeingVegan
// import busyBee from "./modules/busyBee/BusyBeingVegan";
// busyBee();

// ////////////////////////////////////////////////
// // Optional testing for CSS grid animation
// // Do not use with carousel
// // import animateGrid from "./modules/AnimateCssGrid"
// // const animategrid = new animateGrid()

// /////////////////////////////////////////////////
////////////////////////// // React

// import React, { StrictMode } from 'react'
// import ReactDOM from 'react-dom'

// // //Import React components that we created
// import MyAmazingComponent from './modules/MyAmazingComponent'

// ReactDOM.render(<MyAmazingComponent />, document.querySelector('#my-react-example'))
// const root = ReactDOM.createRoot(document.getElementById('my-react-example'))
// root.render(
//   <StrictMode>
//     <MyAmazingComponent />
//   </StrictMode>
// )

// //////////////////////////////////////////////////////
// ///////////////////// AWS - Backend Code
// // prevent secret client area working
// //new ClientArea()

// //////////////////////////////////////////////////////
// Turn on the following for Visible
// new Modal();

// new StickyHeader();
// // new RevealOnScroll(document.querySelectorAll('.feature-item'), 75);
// // new RevealOnScroll(document.querySelectorAll('.testimonial'), 60);
// // the 60 will apear later than 75

// new MobileMenu();

// new ModalContents();

// let modal; // define it here and not in function block

// document.querySelectorAll('.open-modal').forEach(el => {
//   el.addEventListener('click', e => {
//     e.preventDefault();
//     // if modal has not been loaded before ie undefined
//     if (typeof modal == 'undefined') {
//       // weboackChunk is to rename bundled file when imported
//       import(/* webpackChunkName: "modal" */ './modules/Modal')
//         .then(x => {
//           modal = new x.default(); // default() is required by webpack
//           setTimeout(() => modal.openTheModal(), 20); // give browser time
//         })
//         .catch(() => console.log('There was a problem.'));
//       // if modal had been loaded before
//     } else {
//       modal.openTheModal();
//     }
//   });
// });

// /////////////////////////////////////////////////
/////////////// Hot Module Replacement
if (module.hot) {
  module.hot.accept();
}
